export type OrganizerType = {
    name: string;
    description: string;
    image: string;
};
export type SectionType = {
    id: string;
    title: string;
    text: string;
};

export type RegulationType = {
    title: string;
    text: string;
    rules: Array<string>;
};

export type Tag = "desktop" | "mobile";

export const ORGANIZERS: Record<string, OrganizerType> = {
    PAdamczyk: {
        name: "Paulina Adamczyk",
        description: "Koordynator Projektu",
        image: "./images/paula2.jpg",
    },
    MMigacz: {
        name: "Maria Migacz",
        description: "Koordynator ds. Kontaktu z firmami",
        image: "./images/maria2.jpg",
    },
    DKicilinska: {
        name: "Dominika Kicilińska",
        description: "Koordynator ds. Promocji",
        image: "./images/domi.jpg",
    },
    KHanus: {
        name: "Katarzyna Hanus",
        description: "Koordynator ds. Logistyki",
        image: "./images/kasia.jpg",
    },

    PZareba: {
        name: "Paweł Zaręba",
        description: "Koordynator ds. Merytoryki",
        image: "./images/pawel.jpg",
    },
    MGroczany: {
        name: "Mateusz Górczany",
        description: "Koordynator ds. Merytoryki",
        image: "./images/mati.jpg",
    },
    PPichor: {
        name: "Piotr Pichór",
        description: "Koordynator ds. Zasobów ludzkich",
        image: "./images/piotrek.jpg",
    },
};

export const SECTIONS: Record<string, SectionType> = {
    about: {
        id: "about",
        title: "O projekcie",
        text: `
        BEST IT Extended Hackathon, w skrócie BITEhack to projekt realizowany od 4 lat przez Stowarzyszenie Studentów BEST AGH Kraków. 
        BITEhack składa się z trzech równolegle trwających konkurencji, dwie z nich, klasyczna oraz robotyczna realizowane są od samego początku trwania projektu, trzecia kategoria - Artificial Intelligence, powstająca we współpracy z tegorocznym Partnerem Głównym to innowacja wprowadzona w tym roku.
        Uczestnicy, współpracujący w maksymalnie 4-osobowych zespołach, mają 24 godziny na zaproponowanie i przedstawienie własnego rozwiązania postawionego w temacie problemu.
        Wyniki końcowe uczestników oceniać będzie jury składające się z przedstawicieli sponsorów hackathonu oraz pracowników AGH.
        Wydarzenie jest doskonałą okazją nie tylko do rywalizacji między sobą, ale również do rozmowy z przedstawicielami największych firm na krakowskim rynku IT, czy wzięcia udziału w speed recruitment prowadzonym przez naszego Partnera Głównego.
        `,
        //     text: `
        // 	Zajebisty opis projeku, serio lepszego w zyciu nie
        // 	czytaliście (nie działa mi z z kropką na klawiaturze xD). No
        // 	więc generalnie to my tuaj robimy takie fajne ogólnie jakieś
        // 	roboty i komputery tez są, a tak wgl to w tym roku będzie
        // 	tez AI, niezłe jaja. Gdyby ktoś mnie zapytał co najbardziej
        // 	lubię w BITEHack'u, to powiedziałbym ze Michała Golemo.
        // 	Widzieliście jaki ten człowiek jest piękny? Gdybym miał łono
        // 	to w trybie natychmiastowym zabiegałbym o wypełnienie go
        // 	jego potomstwem, pomyślcie jaki świat byłby wtedy
        // 	piękniejszy. Chciałbym spędzić chociaz jedną noc swojego
        // 	zycia bez marzenia o nim. Przeszedłbym całe morze tłuczonego
        // 	szkła na kolanach tylko po to by na chwilę połozyć głowę na
        // 	jego kolanach. Matko boska, jak ja kocham Michała Golemo.
        // 	uwu
        // `,
    },
    organisers: {
        id: "organisers",
        title: "Organizatorzy",
        text: "Paulina A.",
    },
    regulations: {
        id: "regulations",
        title: "Regulamin",
        text: "Tutaj będzie regulamin",
    },
};

export const WELCOME_MESSAGE = String.raw`
                                 .::::::::::::                                
                      .:--. :-:...:. :-:                              
                   .:-:      .--:::-:  -=:                            
                 :++-::-          ::::..:-::==::--:-=::=+.            
                    .-=:    .. .:-.  ..    :.   -:..  --              
                  .--.  .:.:-.-=.   .-.  ..         :=.               
                .--.  .=:-:.:-.       .:::::::==--=-:                 
               :=        .:::-       +-       =--*:                   
              .*...     .-.  -       :-:.                             
              .::::.       ::   .=:    .=+*:                          
                        :-:       :-:.. .-**+:                        
                                    :::::..:   
                                
            ____ _____ _______ ______ _                _    
            |  _ \_   _|__   __|  ____| |              | |   
            | |_) || |    | |  | |__  | |__   __ _  ___| | __
            |  _ < | |    | |  |  __| | '_ \ / _' |/ __| |/ /
            | |_) || |_   | |  | |____| | | | (_| | (__|   < 
            |____/_____|  |_|  |______|_| |_|\__,_|\___|_|\_\
                                                                
                                                                 
        Witaj przybyszu!
        Jeśli chcesz przeżyć niezapomnianą przygodę, wpisz 'cd BITEhack'


        Aby poznać dostępne komendy, wpisz: 'help'
        Wyjśce: 'exit'
`;

export const CATEGORIES: Record<string, string> = {
    classic:
        "Zadanie z kategorii klasycznej będzie wymagać od Was napisania aplikacji komputerowej przy użyciu wybranego przez Was języka programowania. Temat kategorii poznacie wraz z rozpoczęciem hackathonu.",
    robotic:
        "Jako uczestnicy będziecie mieć za zadanie stworzyć robota oraz napisać oprogramowanie, które posłuży do jego obsługi. Temat kategorii robotycznej zostanie podany z wyprzedzeniem, a części potrzebne na jego realizację zostaną zapewnione przez organizatorów.",
    ai: "Waszym zadaniem będzie stworzenie oprogramowania o zadanej funkcyjności bazując na tematach sztucznej inteligencji oraz technologiach z nią związanych. Temat kategorii zostanie podany równocześnie z tematem kategorii klasycznej.",
};

export const REGISTRATION_PAGE = "https://google.com";

export const HACKATHON_DESCRIPTION = "...";

export const COMMANDS_DESCRIPTIONS: Record<string, string> = {
    show: "Pokaż wiadomość powitalną",
    clear: "Wyczyść ekran",
    help: "Wyświetl dostępne komendy",
    cd: "Zmień katalog",
    "./register.sh": "Zarejestruj swoją drużynę",
    "register.sh": "Zarejestruj swoją drużynę",
    man: "Instrukcja",
    ls: "Wypisz pliki",
    cat: "Wypisz zawartość pliku tekstowego",
    exit: "Wyjdź",
};
