import React from "react";
import { isMobile } from "react-device-detect";
import { Tag } from "../constants/constants";
import "../styles/Footer.sass";

const Footer: React.FC = () => {
    const className: Tag = isMobile ? "mobile" : "desktop";
    return (
        <footer id="mainFooter" className={className}>
            <div id="footerSection">
                <div id="footerHeader">Organizator</div>
                <div id="footerContent">
                    <img
                        src="https://bitehack.best.krakow.pl/res/images/best-logo.png"
                        alt="Best logo"
                    />
                </div>
            </div>
            <div id="footerSection">
                <div id="footerHeader">
                    Stowarzyszenie Studentów
                    <br />
                    BEST AGH Kraków
                </div>
                <div id="footerContent">
                    I DS Alfa Blok 1
                    <br />
                    Ul. Reymonta 17/E14
                    <br />
                    30-059 Kraków
                    <br />
                    <br />
                    <a href="https://www.newsite.best.krakow.pl/">
                        www.BEST.krakow.pl
                    </a>
                </div>
            </div>
            <div id="footerSection">
                <div id="footerHeader">Koordynator Projektu</div>
                <div id="footerContent">
                    Paulina Adamczyk
                    <br />
                    <a href="mailto:paulina.adamczyk@best.krakow.pl">
                        paulina.adamczyk@best.krakow.pl
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
