import React from "react";
import "../styles/MenuBar.sass";
import { SECTIONS } from "../constants/constants";
import { isMobile } from "react-device-detect";
import logoWhite from "../media/white.svg";

interface IState {
    navClass: string;
    scrollingDown: boolean;
    deviceClass: string;
}

interface IProps {
    onSelect: React.Dispatch<React.SetStateAction<number>>;
}

class MenuBar extends React.Component<IProps, IState> {
    previousScrollPosition = 0;
    throttleWait = false;
    state: IState = { navClass: "", scrollingDown: false, deviceClass: "" };
    // TODO mniejsza czcionka na mobilnym

    componentDidMount() {
        this.setState({ deviceClass: isMobile ? "mobile" : "desktop" });
        window.addEventListener("scroll", () => {
            this.throttle(this.handleScroll, 200);
        });
    }

    isScrollingDown() {
        let currentScrolledPosition = window.scrollY || window.pageYOffset;
        let scrollingDown: boolean;
        if (currentScrolledPosition > this.previousScrollPosition) {
            // scrollingDown = true;
            this.setState({ scrollingDown: true });
        } else {
            // scrollingDown = false;
            this.setState({ scrollingDown: false });
        }

        this.previousScrollPosition = currentScrolledPosition;
        return this.state.scrollingDown;
    }

    handleScroll = () => {
        //arrow function bo się kontekst psuje :c
        if (this.isScrollingDown()) {
            this.setState({ navClass: "scroll-down" });
        } else {
            this.setState({ navClass: "scroll-up" });
        }
    };

    throttle(f: () => void, time: number) {
        if (this.throttleWait) return;
        f();
        this.throttleWait = true;

        setTimeout(() => {
            // f();
            this.throttleWait = false;
        }, time);
    }

    render() {
        // let links = [];
        // for (const [_, section] of Object.entries(SECTIONS)) {
        //     let id = `#${section.id}`;
        //     links.push(
        //         <a className="navLink" href={id}>
        //             {section.title}
        //         </a>
        //     );
        // }

        const links = Object.values(SECTIONS).map((sec, i) => {
            return (
                <a
                    href={sec.id}
                    className="navLink"
                    onClick={(e) => {
                        e.preventDefault();
                        this.props.onSelect(i);
                    }}
                >
                    {sec.title}
                </a>
            );
        });

        return (
            <nav
                // className={`navBar ${this.state.navClass} ${this.state.deviceClass}`}
                className={`navBar ${this.state.navClass}`}
            >
                <img
                    src={logoWhite}
                    alt="LOGO"
                    className={this.state.deviceClass}
                />
                {/* <div id="navLogo">
                    <img src={logoWhite} alt="LOGO" />
                </div> */}
                <div id="navLinks" className={this.state.deviceClass}>
                    {links}
                </div>
            </nav>
        );
    }
}

export default MenuBar;
