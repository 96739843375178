import React, { useState } from "react";
import "../styles/CountDownTimer.sass";

interface IProps {
    target: Date;
}

const CountDownTimer: React.FC<IProps> = ({ target }) => {
    const [text, setText] = useState("");

    setInterval(() => {
        const currentTime = new Date().getTime();
        const targetTime = target.getTime();

        const distance = targetTime - currentTime;

        if (distance < 0) {
            setText("Za późno :c");
            return;
        }

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setText(`${days}d ${hours}h ${minutes}m ${seconds}s`);
    }, 1000);

    return <div id="countDownTimer">{text}</div>;
};

export default CountDownTimer;
