import React, { useState, useEffect } from "react";
import MainPage from "./MainPage";
import "../styles/App.sass";
import MenuBar from "./MenuBar";
import OrganizersPage from "./OrganizersPage";
import RegulationsPage from "./RegulationsPage";
import BHTerminal from "./Terminal";
import disableScroll from "disable-scroll";

interface IProps {
    terminalEnabled: boolean;
}

const App: React.FC<IProps> = ({ terminalEnabled }) => {
    const [terminal, setTerminal] = useState(terminalEnabled);
    const [page, setPage] = useState(0);
    const pages = [<MainPage />, <OrganizersPage />, <RegulationsPage />];
    useEffect(() => window.scrollTo(0, 0), []);

    if ("scrollRestoration" in window.history) {
        window.history.scrollRestoration = "manual";
    }

    if (terminalEnabled) {
        disableScroll.on();
    }
    const getContent = () => {
        if (terminal) {
            return (
                <div className="content">
                    <div id="terminalWrapper">
                        <BHTerminal terminalVisible={setTerminal} />
                    </div>
                    <div id="page">
                        <MenuBar onSelect={setPage} />
                        {pages[page]}
                    </div>
                </div>
            );
        } else {
            disableScroll.off();
            return (
                <div className="content">
                    <MenuBar
                        onSelect={(i) => {
                            window.scrollTo(0, 0);
                            setPage(i);
                        }}
                    />
                    {pages[page]}
                </div>
            );
        }
    };

    return getContent();
};

export default App;
