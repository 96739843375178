import React, { useEffect } from "react";
import "../styles/Organizer.sass";
import AOS from "aos";

interface IProps {
    left: boolean;
    img: string;
    name: string;
    description: string;
    aos_styles: string;
}

const Organizer: React.FC<IProps> = (props) => {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);
    return (
        <div id="PageWrapper" data-aos={props.aos_styles}>
            <div
                className={`organizerInfoWrapper ${props.left ? "" : "right"}`}
            >
                <div id="imageWrapper">
                    <img src={props.img} alt={props.name} />
                </div>
                <div id="infoWrapper" className={props.left ? "" : "right"}>
                    <h2 id="name">{props.name}</h2>
                    <p id="description">{props.description}</p>
                </div>
            </div>
        </div>
    );
};

export default Organizer;
