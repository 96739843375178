import React, { useEffect, useRef } from "react";
import CountDownTimer from "./CountDownTimer";
import "../styles/MainPage.sass";
import BiteHackLogo from "../media/logo3.svg";
import { isMobile } from "react-device-detect";
import Section from "./Section";
import { SECTIONS, Tag } from "../constants/constants";
import Aos from "aos";
import Footer from "./Footer";

const MainPage: React.FC = () => {
    const className: Tag = isMobile ? "mobile" : "desktop";

    useEffect(() => {
        Aos.init({ duration: 800 });
    }, []);
    return (
        <div id="mainPage">
            <header id="mainHeader" className={className}>
                <img id="mainLogo" src={BiteHackLogo} className={className} />
                <div id="headerText">
                    <CountDownTimer target={new Date("Jan 15 2022 15:00")} />
                    <strong id="bigText">HACKATHON</strong>
                    <p>15 - 16 Stycznia</p>
                </div>
            </header>

            <div id="enrollment" className={className}>
                <button id="enrollmentButton">ZAPISZ SIĘ</button>
            </div>

            <Section {...SECTIONS.about} aos_styles="fade-up" tag={className} />
            <div id="categories" className={className}>
                <fieldset id="category">
                    <legend>KLASYCZNA</legend>
                </fieldset>
                <fieldset id="category">
                    <legend>ARTIFFICIAL INTELIGENCE</legend>
                </fieldset>
                <fieldset id="category">
                    <legend>ROBOTYCZNA</legend>
                </fieldset>
            </div>

            <div id="info-pieces" className={className}>
                <div id="item">
                    <p id="item-header">3 kategorie:</p>
                    <p id="item-text">
                        Pisanie aplikacji, tworzenie robotów, czy projektowanie
                        oprogramowania? Stwórzcie razem doskonały projekt, w
                        którym jedyne co was ogranicza to wyobraźnia.
                    </p>
                </div>
                <div id="item">
                    <p id="item-header">Atrakcje</p>
                    <p id="item-text">
                        Na wydarzeniu nie ma miejsca na nudę! Organizatorzy
                        zadbają o to, abyście zapamiętali nasz hackathon i mieli
                        z niego miłe wspomnienia.
                    </p>
                </div>
                <div id="item">
                    <p id="item-header">Jeszcze się wahasz?</p>
                    <p id="item-text">
                        Jeśli lubisz informatykę, pasjonujesz się tematami AI,
                        robotyka to twoje hobby i nie brak Ci wyobraźni, aby
                        stworzyć nowoczesne i innowacyjne rozwiązania, zbierz
                        drużynę i wpadnij na BITEhacka!
                    </p>
                </div>
            </div>
            <div
                data-aos="new-animation"
                id="sponsorsWrapper"
                className={className}
            >
                <p id="content">SPONSORZY</p>
            </div>
            <Footer />
        </div>
    );
};

export default MainPage;
