import React from "react";
import Terminal from "terminal-in-react";
import {
    WELCOME_MESSAGE,
    CATEGORIES,
    REGISTRATION_PAGE,
    COMMANDS_DESCRIPTIONS,
    SECTIONS,
} from "../constants/constants";

const printTextNicely = (text: string, print: any, runCommand: any) => {
    for (let i = 0; i < text.length; i += 1) {
        setTimeout(() => {
            runCommand(`edit-line ${text.slice(0, i + 1)}`);
        }, 100 * i);
    }
};

const BHTerminal = (props: any) => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // height: "95",
                // width: "200vh"
                //   draggable: true
            }}
        >
            <Terminal
                color="green"
                backgroundColor="#0e1111"
                barColor="grey"
                allowTabs={true}
                msg={WELCOME_MESSAGE}
                style={{
                    fontWeight: "bold",
                    fontSize: "1em",
                    height: "80vh",
                }}
                commands={{
                    cd: (args: any, print: any, runCommand: any) => {
                        if (args[1] === "BITEhack") {
                            props.terminalVisible(false);
                            return;
                        }
                        print("Wspierane parametry: BITEhack");
                    },
                    "./register.sh": (
                        args: any,
                        print: any,
                        runCommand: any
                    ) => {
                        print(`zapisy nie są jeszcze dostępne :(`);
                        // window.open(REGISTRATION_PAGE);
                    },
                    "register.sh": (args: any, print: any, runCommand: any) => {
                        print(`zapisy nie są jeszcze dostępne :(`);
                        // window.open(REGISTRATION_PAGE);
                    },
                    man: (args: any, print: any, runCommand: any) => {
                        if (args[1] === "BITEhack") {
                            print(SECTIONS.about.text);
                            return;
                        }
                        print(
                            "Dostępna dokumentacja: BITEhack",
                            print,
                            runCommand
                        );
                    },
                    ls: (args: any, print: any, runCommand: any) => {
                        print("BITEhack");
                        print("register.sh");
                        print("klasyczna.txt");
                        print("robotyczna.txt");
                        print("ai.txt");
                    },
                    cat: (args: any, print: any, runCommand: any) => {
                        switch (args[1]) {
                            case "klasyczna.txt":
                                print(CATEGORIES.classic);
                                break;
                            case "robotyczna.txt":
                                print(CATEGORIES.robotic);
                                break;
                            case "ai.txt":
                                print(CATEGORIES.ai);
                                break;
                            default:
                                print(
                                    "Pliku nie da się wyświetlić, bądź nie istnieje."
                                );
                        }
                    },
                    exit: () => props.terminalVisible(false),
                }}
                descriptions={{ ...COMMANDS_DESCRIPTIONS }}
                // shortcuts={{
                // 	'darwin,win,linux': {
                // 	'esc': () => props.terminalVisible(false)
                // 	},
                // }}
                actionHandlers={{
                    handleClose: (toggleClose) => {
                        props.terminalVisible(false);
                    },
                    handleMaximise: (toggleMaximize) => {},
                }}
            />
        </div>
    );
};

export default BHTerminal;
