import App from "./components/App";
import { render } from "react-dom";
// import { TerminalContextProvider } from "react-terminal";
import { BrowserView, MobileView } from "react-device-detect";

render(
    <div>
        <BrowserView>
            {/* <TerminalContextProvider> */}
            <App terminalEnabled={true} />
            {/* </TerminalContextProvider> */}
        </BrowserView>
        <MobileView>
            <App terminalEnabled={false} />
        </MobileView>
    </div>,

    document.querySelector("#root")
);
