import React from "react";
import "../styles/OrganizersPage.sass";
import { ORGANIZERS, Tag } from "../constants/constants";
import { isMobile } from "react-device-detect";
import Organizer from "./Organizer";
import MobileOrganier from "./MobileOrganizer";
import Footer from "./Footer";

const OrganizersPage: React.FC = () => {
    const tag: Tag = isMobile ? "mobile" : "desktop";
    let content;

    if (!isMobile) {
        content = Object.values(ORGANIZERS).map((org, i) => {
            return (
                <Organizer
                    key={i}
                    left={i % 2 == 0}
                    img={org.image}
                    name={org.name}
                    description={org.description}
                    aos_styles="fade-up"
                />
            );
        });
    } else {
        content = Object.values(ORGANIZERS).map((org, i) => {
            return (
                <MobileOrganier
                    key={i}
                    img={org.image}
                    name={org.name}
                    description={org.description}
                    // aos_styles="fade-up"
                    // aos_styles=""
                />
            );
        });
    }
    return (
        <div id="organizersPage">
            <div id="organizersWrapper" className={tag}>
                <div id="headerText">
                    <strong>ORGANIZATORZY</strong>
                </div>
                {content}
            </div>
            <Footer />
        </div>
    );
};

export default OrganizersPage;
