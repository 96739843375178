import React, { useState } from "react";
import "../styles/MobileOrganizer.sass";

interface IProps {
    img: string;
    name: string;
    description: string;
    aos_styles?: string;
}

const MobileOrganier: React.FC<IProps> = (props) => {
    const [description, setDescription] = useState(false);
    // const [viewClass, setViewClass] = useState("hidden")
    // const content = description ? (
    //     <div id="infoWrapper">{props.description}</div>
    // ) : null;
    return (
        <div id="pageWrapper">
            <div id="titleWrapper">{props.name}</div>
            <div id="imageWrapper" onClick={() => setDescription(!description)}>
                <img src={props.img} alt={props.name} />
            </div>
            {/* {content} */}
            <div id="infoWrapper" className={description ? "show" : "hide"}>
                <div id="infoWrapperInside">{props.description}</div>
            </div>
        </div>
    );
};

export default MobileOrganier;
