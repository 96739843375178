import React, { useEffect } from "react";
import "aos/dist//aos.css";
import AOS from "aos";
import "../styles/Section.sass";

type Tag = "desktop" | "mobile";

interface IProps {
    title: string;
    text: string;
    tag: Tag;
    id: string;
    aos_styles: string;
}

const Section: React.FC<IProps> = (props) => {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);
    return (
        <div id="section" className={props.tag} data-aos={props.aos_styles}>
            <h3>{props.title}</h3>
            <p>{props.text}</p>
        </div>
    );
};

export default Section;
